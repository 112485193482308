import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { fadeInRight } from 'ng-animate';

@Component({
    moduleId: module.id,
    selector: 'team-fadi-about',
    templateUrl: 'team-fadi-about.component.html',
    styleUrls: ['team-fadi-about.component.scss'],
    animations: [
      trigger('fadeInRight', [transition('* => *', useAnimation(fadeInRight))])]
})
export class TeamFadiAboutComponent implements OnInit {
  fadeInRight: any;

    constructor() { }

    ngOnInit() {
    }



    // <!-- Navigation Start------------------------------------------------------------------- -->
    // <!-- Navigation End--------------------------------------------------------------------- -->
    ///////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////
    // <!-- Header Start----------------------------------------------------------------------- -->
    headercarousel = [
        {
            tagLine1: "TEAM FADI",
            tagLine2: "WHO WE ARE",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took."
        }
    ]
    headercarouselOptions = {
        items: 1,
        margin: 0,
        autoHeight: true,
    }
      // <!-- Header End------------------------------------------------------------------------ -->
      //////////////////////////////////////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////////////////////////////////
        // <!-- Capabilities Start-------------------------------------------------------------- -->
    services = [
        {
          name:'Marketing',
          img:'assets/images/team-fadi/web.webp',
          description:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of.',
        },
        {
          name:'Branding',
          img:'assets/images/team-fadi/seo.webp',
          description:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of.',
        },
        {
          name:'Desigining',
          img:'assets/images/team-fadi/web.webp',
          description:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of.',
        },
        {
          name:'Animations',
          img:'assets/images/team-fadi/branding.webp',
          description:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of.',
        },
        {
          name:'Consulting',
          img:'assets/images/team-fadi/social.webp',
          description:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of.',
        },
      ]
    // <!-- Capabilities End---------------------------------------------------------------------- -->
    //////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////
    // <!--  Footer Start------------------------------------------------------------------------- -->
    // <!--  Footer End--------------------------------------------------------------------------- -->
    //////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////
    // <!--  Copyright Start---------------------------------------------------------------------- -->
    // <!--  Copyright End------------------------------------------------------------------------ -->

}
