import { Component, OnInit } from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'team-fadi-contactus',
    templateUrl: 'team-fadi-contactus.component.html',
    styleUrls: ['team-fadi-contactus.component.scss']
})
export class TeamFadiContactusComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }



    // <!-- Navigation Start-------------------------------------------------------------------------------------- -->
    // <!-- Navigation End---------------------------------------------------------------------------------------- -->
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // <!-- Header Start-------------------------------------------------------------------------------------- -->
    headercarousel = [
        {
            tagLine1: "TEAM FADI",
            tagLine2: "WHO WE ARE",
           // description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took."
        },
        // {
        //     tagLine1: "build your",
        //     tagLine2: "body more strong",
        //    // description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took."
        // },
    ]

    headercarouselOptions = {
        items: 1,
        margin: 0,
        autoHeight: true,
        nav: false,
        autoplay: false,
        slideSpeed: 300,
        paginationSpeed: 400,
        loop: true,
    }
    // <!-- Header End---------------------------------------------------------------------------------------- -->
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // <!--  Footer Start-------------------------------------------------------------------------------------- -->
    // <!--  Footer End---------------------------------------------------------------------------------------- -->
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // <!--  Copyright Start-------------------------------------------------------------------------------------- -->
    // <!--  Copyright End---------------------------------------------------------------------------------------- -->

}
