<!--component html goes here -->
<!-- Navigation bar Start------------------------------------------------------------------- -->
<header class="gym loding-header header-fixed position-fixed nav-abs custom-scroll nav-lg"
    [ngStyle]="{'background-color': 'rgb(255 212 0 / 80%)'  }">
    <div class="container">
        <div class="row">
            <div class="col">
                <nav>
                    <a href="#" class="d-inline-block m-r-auto">
                        <img src="assets/images/logo/1.png" alt="" class="img-fluid">
                    </a>
                    <app-menu></app-menu>
                </nav>
            </div>
        </div>
    </div>
</header>
<!-- Navigation bar End--------------------------------------------------------------------- -->
<!-- ///////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////// -->
<!-- Header Start--------------------------------------------------------------------------- -->
<section class="gym header" id="home">
    <div class="header5-content">
        <owl-carousel-o [options]="headercarouselOptions" class="gym-slider">
            <ng-container *ngFor="let header of headercarousel" class="scroll">
                <ng-template carouselSlide class="item">
                    <div class="gym-header bg" class="bg-scroll"
                        [ngStyle]="{'background-image': 'url(assets/images/team-fadi/header-01.jpg)'}"
                        style="background-repeat: no-repeat; background-position: center; background-size: cover; object-fit: fill;">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-10 offset-md-1">
                                    <div class="center-text">
                                        <div class="text-center">
                                            <div class="header-text">
                                                <h1><span
                                                        class="bold-text">{{header.tagLine1}}</span>{{header.tagLine2}}
                                                </h1>
                                            </div>
                                            <div class="header-sub-text">
                                                <p class="text-white p-light">{{header.description}}</p>
                                            </div>
                                            <div class="link-horizontal">
                                                <ul class="justify-content-center">
                                                    <li>
                                                        <a class=" btn btn-default"><span>register
                                                                20%<sup>off</sup></span></a>
                                                    </li>
                                                    <li>
                                                        <a class=" btn btn-default">start now</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ng-container>
        </owl-carousel-o>
    </div>
</section>
<!-- Header End----------------------------------------------------------------------------- -->
<!-- ///////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////// -->
<!-- client Start--------------------------------------------------------------------------- -->
<section class="event sponsor set-relative bg bg-img2 bg-about"
    [ngStyle]="{'background-image': 'url(assets/images/agency/team/expert-background.png)'}"
    style="margin-bottom: 50px; margin-top: 50px;">
    <div class="container set-margin">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title title3">
                    <div class="main-title">
                        <h2 class=" text-whit">Our Clients</h2>
                    </div>
                    <div class="sub-title">
                        <P class="text-whit">Register now and reserve your seat for this
                            <span>year's unice,</span> the largest <span>web development</span> and online marketing
                            <span>conference in UK,</span> covering everything you need to know in order to develop a
                            successful online business.
                        </P>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-4 col-6 sponsor-img" *ngFor="let data of client">
                <div class="text-center">
                    <a href="#"><img [src]="data.img" alt="" class="img-fluid"></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- client End----------------------------------------------------------------------------- -->
<!-- ///////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////// -->
<!-- reviwe start--------------------------------------------------------------------------- -->
<section class="agency testimonial format testimonial-bg"
    [ngStyle]="{'background-image': 'url(assets/images/agency/team/expert-background.png)'}"
    style="margin-bottom: 20px; margin-top: 50px;">
    <div class="container">
        <div class="row">
            <div class="col-md-5">
                <div class="format-container m-b-50">
                    <h6 class="borders-before">
                        <span style="color: black;">Happy Clients</span>
                    </h6>
                    <div class="format-head-text">
                        <h2 class=" text-whit" style="color: black;">We Will Ready For <span class="block-span"
                                style="color: black;">Your Services</span>
                        </h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <owl-carousel-o [options]="testimonialCarouselOptions" class="testimonial-slider">
                    <ng-container *ngFor="let user of users">
                        <ng-template carouselSlide class="item">
                            <div class="testimonial-container testimonial-container2 m-0">
                                <div class="d-flex">
                                    <img [src]="user.img" alt="" class="img-fluid m-r-25">
                                    <div class="center-content">
                                        <h6 class="name">{{user.name}}</h6>
                                        <h6 class="post font-primary">{{user.designation}}</h6>
                                    </div>
                                </div>
                                <div class="testimonial-info">
                                    <p class="m-b-20 para2">{{user.review}}</p>
                                    <div class="primary-border"></div>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>
<!-- reviwe end----------------------------------------------------------------------------- -->
<!-- ///////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////// -->
<!--  Footer Start-------------------------------------------------------------------------- -->
<footer class="gym footer2">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 set-first">
                <div class="logo-sec">
                    <div class="footer-title mobile-title">
                        <h3 class="text-white">TEAM FADI</h3>
                    </div>
                    <div class="footer-contant">
                        <img src="assets/images/logo/1.png" alt="" class="img-fluid footer-logo">
                        <div class="footer-para">
                            <h6 class="text-white para-address">TEAM FADI</h6>
                            <h6 class="text-white para-address">Office No# 123, Second Floor, </h6>
                            <h6 class="text-white para-address">Karachi, Pakistan.</h6>
                        </div>
                        <ul class="d-flex footer-social social">
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                            </li>
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                            </li>
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-google" aria-hidden="true"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Team footer-headings</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">about</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a href="#">services</a>
                            </li>
                            <li class="">
                                <a href="#">projects</a>
                            </li>
                            <li class="">
                                <a href="#">Clients</a>
                            </li>
                            <li class="">
                                <a href="#">Contact US</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Services</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Services-01</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a href="#">Services-02</a>
                            </li>
                            <li class="">
                                <a href="#">Services-03</a>
                            </li>
                            <li class="">
                                <a href="#">Services-04</a>
                            </li>
                            <li class="">
                                <a href="#">Services-05</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white"> Projects</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Projects-01</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a href="#">Projects-02</a>
                            </li>
                            <li class="">
                                <a href="#">Projects-03</a>
                            </li>
                            <li class="">
                                <a href="#">Projects-04</a>
                            </li>
                            <li class="">
                                <a href="#">Projects-05</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 col-sm-12 set-last">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Post Tags</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Post Tags</h5>
                    <div class="link-btns">
                        <ul>
                            <li class="buttons">
                                <a href="#">app</a>
                            </li>
                            <li class="buttons">
                                <a href="#">business</a>
                            </li>
                            <li class="buttons">
                                <a href="#">corporation</a>
                            </li>
                            <li class="buttons">
                                <a href="#">creative</a>
                            </li>
                            <li class="buttons">
                                <a href="#">design</a>
                            </li>
                            <li class="buttons">
                                <a href="#">fashion</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    </div>
</footer>
<app-tap-to-top></app-tap-to-top>
<!--  Footer End---------------------------------------------------------------------------------------- -->
<!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
<!--  Copyright Start-------------------------------------------------------------------------------------- -->
<div class="gym copyright">
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
                <div class="link-horizontal">
                    <ul>
                        <li>
                            <a href="#" class="copyright-text op-text">Privacy Policy</a>
                        </li>
                        <li>
                            <a href="#" class="copyright-text op-text">Terms & Conditions</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-sm-6">
                <div>
                    <h6 class="copyright-text text-white text-right op-text">Copyright ©2023 by <i class="fa fa-heart"
                            aria-hidden="true"></i>
                        Team Fadi</h6>
                </div>
            </div>
        </div>
    </div>
</div>
<!--  Copyright End---------------------------------------------------------------------------------------- -->