import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TeamFadiComponent } from './layouts/team-fadi/team-fadi.component';
//import { NavService } from './shared/service/nav.service';
import { TeamFadiIndexComponent } from './layouts/team-fadi-index/team-fadi-index.component';
import { TeamFadiAboutComponent } from './layouts/team-fadi-about/team-fadi-about.component';
import { TeamFadiProjectsComponent } from './layouts/team-fadi-projects/team-fadi-projects.component';





export const routes: Routes = [


  {
    path: 'index',
    redirectTo: '',
    pathMatch: 'full',

  },
  {
    path: '',
    component: TeamFadiIndexComponent
  },
   {
    path: '',
    component: TeamFadiAboutComponent
  },
     {
    path: '',
    component: TeamFadiProjectsComponent
  },
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
