<!--component html goes here -->
<!-- Navigation bar Start------------------------------------------------------------------- -->
<header class="gym loding-header header-fixed position-fixed nav-abs custom-scroll nav-lg"
    [ngStyle]="{'background-color': 'rgb(255 212 0 / 80%)'  }">
    <div class="container">
        <div class="row">
            <div class="col">
                <nav>
                    <a href="#" class="d-inline-block m-r-auto">
                        <img src="assets/images/logo/1.png" alt="" class="img-fluid">
                    </a>
                    <app-menu></app-menu>
                </nav>
            </div>
        </div>
    </div>
</header>
<!-- Navigation bar End--------------------------------------------------------------------- -->
<!-- ///////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////// -->
<!-- Header Start--------------------------------------------------------------------------- -->
<section class="gym header" id="home">
    <div class="header5-content">
        <owl-carousel-o [options]="headercarouselOptions" class="gym-slider">
            <ng-container *ngFor="let header of headercarousel">
                <ng-template carouselSlide class="item">
                    <div class="gym-header bg"
                        [ngStyle]="{'background-image': 'url(assets/images/team-fadi/header-04.jpg)'}">

                        <div class="container">
                            <div class="row">
                                <div class="col-md-10 offset-md-1">
                                    <div class="center-text">
                                        <div class="text-center">
                                            <div class="header-text">
                                                <h1><span class="bold-text">{{header.tagLine1}}
                                                    </span>
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ng-container>
        </owl-carousel-o>
    </div>
</section>
<!-- Header End--------------------------------------------------------------------------- -->
<!-- /////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////// -->
<!-- About Start-------------------------------------------------------------------------- -->
<section class="gym format" id="about">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="text-center center-content">
                    <div class="about-img">
                        <div class="borders-around">
                            <div>
                                <div class="bg-black"></div>
                            </div>
                            <img src="assets/images/team-fadi/about.jpg" alt="" class="img-fluid format-img">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="center-text">
                    <div>
                        <div class="format-small-text">
                            <h6 class="font-primary borders-before"><span>our progress</span></h6>
                        </div>
                        <div class="format-head-text">
                            <h3 class="about-font-header gradient-text">about us</h3>
                        </div>
                        <div class="format-sub-text">
                            <p class="p-light about-para">Lorem Ipsum is simply dummy text of the printing and
                                typesetting
                                industry. Lorem Ipsum
                                has been the industry's standard dummy text ever since the 1500s.</p>
                            <p class="p-light about-para">When an unknown printer took a galley of type and scrambled it
                                to make
                                a type specimen
                                book. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                Ipsum has been the industry.</p>
                        </div>
                        <a href="javascript:void(0)" class="btn btn-default primary-btn">read more</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- About End------------------------------------------------------------------------------ -->
<!-- ///////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////// -->
<!--capabilities start---------------------------------------------------------------------- -->
<section class="resume schedule">
    <div class="container">
        <div class="title title2">
            <div class="row">
                <div class=" offset-md-2 col-md-8">
                    <!-- <h6 class="font-primary borders main-text text-uppercase"><span>details</span></h6> -->
                    <div class="sub-title">
                        <div class="">
                            <!-- <h2 class="title-text">services & experience</h2> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="cal-time">
                    <div class="time">
                        <h3 class="text-capitalize">Our Capabilities</h3>
                    </div>
                </div>
                <div class="event-container scrollbar">
                    <div>
                        <div class=" cal-time events" *ngFor="let data of services">
                            <div class="row">
                                <div class="col-md-10">
                                    <div class="schedule-event">
                                        <h4 class="names">{{data.name}}</h4>
                                        <img [src]="data.img" alt="" class="img-fluid schedule-img"
                                            style="width: 400px; height: 400px;">
                                        <p class="schedule-description">{{data.description}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--capabilitis end------------------------------------------------------------------------- -->
<!-- ///////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////// -->
<!-- solution start------------------------------------------------------------------------- -->
<section class="agency format service-bg" id="features"
    [ngStyle]="{'background-image': 'url(assets/images/agency/service/services-backgrondpng.png)'}">
    <div class="container">
        <div class="row">
            <div class="col-md-4 offset-lg-1">
                <div class="center-text">
                    <div class="format-container">
                        <h6 class="borders-before text-uppercase">
                            <span class="">Our solution</span>
                        </h6>
                        <div class="format-head-text">
                            <h2 class="about-font-header">solution </h2>
                        </div>
                        <div class="format-sub-text">
                            <p class="about-para">Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                sed do eiusmod tempor incididunt.</p>
                        </div>
                        <!-- <a href="#" class=" btn btn-default btn-gradient text-white">view more</a> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-7 col-md-8">
                <div class="row">
                    <div class="col-sm-4 center-content unset-height">
                        <ul class="icon-collection"
                            [@fadeInRight]="{ value: fadeInRight, params: { timing: 3, delay: 0 } }">
                            <li class="about-icon">
                                <a href="#" class="center-content"><img src="assets/images/agency/service/1.png" alt="">
                                    <h5>Buisness Planning</h5>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-sm-4 center-content unset-height">
                        <ul class="icon-collection"
                            [@fadeInRight]="{ value: fadeInRight, params: { timing: 3, delay: 2 } }">
                            <li class="about-icon">
                                <a href="#" class="center-content"><img src="assets/images/agency/service/2.png" alt="">
                                    <h5>Divorce Planning</h5>
                                </a>
                            </li>
                            <li class="about-icon">
                                <a href="#" class="center-content"><img src="assets/images/agency/service/3.png" alt="">
                                    <h5>Finance Planning</h5>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-sm-4 center-content unset-height">
                        <ul class="icon-collection"
                            [@fadeInRight]="{ value: fadeInRight, params: { timing: 3, delay: 3 } }">
                            <li class="about-icon">
                                <a href="#" class="center-content"><img src="assets/images/agency/service/4.png" alt="">
                                    <h5>Estate Planning</h5>
                                </a>
                            </li>
                            <li class="about-icon">
                                <a href="#" class="center-content"><img src="assets/images/agency/service/5.png" alt="">
                                    <h5>Build Planning</h5>
                                </a>
                            </li>
                            <li class="about-icon">
                                <a href="#" class="center-content"><img src="assets/images/agency/service/6.png" alt="">
                                    <h5>Invest Planning</h5>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- solution end--------------------------------------------------------------------------- -->
<!-- ///////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////// -->
<!-- Our Team  start------------------------------------------------------------------------ -->

<div class="sub-title" style="text-align: center; align-items: center;">
    <h2 class="title-text" style="font-size: 25px; font-weight: 600;">services & experience</h2>
</div>
<section class="event team-sec team-grid speaker set-relative" id="speaker">
    <div class="container">
        <div class="row">
            <div class="col-lg-10 offset-lg-1 speker-container">
                <div class="text-center d-flex">
                    <div class="team-img ">
                        <img src="assets/images/team-fadi/branding.webp" alt="" class="img-fluid"
                            style="width: 200px; height: 200px;">
                    </div>
                    <div class="employee">
                        <h5 class="e-name text-center">Vicky Smith</h5>
                        <h6 class="post text-center ">UI/UX Designer - Little Big</h6>
                        <p class="team-para">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                            galley of type and scrambled it to make a type specimen book.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-10 offset-lg-1 speker-container">
                <div class="text-center d-flex">
                    <div class="team-img">
                        <img src="assets/images/team-fadi/web.webp" alt="" class="img-fluid"
                            style="width: 200px; height: 200px;">
                    </div>
                    <div class="employee">
                        <h5 class="e-name text-center">Sam Rowling</h5>
                        <h6 class="post text-center ">Team Leader - otstrab</h6>
                        <p class="team-para">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                            galley of type and scrambled it to make a type specimen book.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-10 offset-lg-1 speker-container">
                <div class="text-center d-flex">
                    <div class="team-img">
                        <img src="assets/images/team-fadi/social.webp" alt="" class="img-fluid"
                            style="width: 200px; height: 200px;">
                    </div>
                    <div class="employee">
                        <h5 class="e-name text-center">Mark Tucker</h5>
                        <h6 class="post text-center ">App Developer - Jumpster</h6>
                        <p class="team-para">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                            galley of type and scrambled it to make a type specimen book.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-10 offset-lg-1 speker-container">
                <div class="text-center d-flex">
                    <div class="team-img">
                        <img src="assets/images/team-fadi/seo.webp" alt="" class="img-fluid"
                            style="width: 200px; height: 200px;">
                    </div>
                    <div class="employee">
                        <h5 class="e-name text-center">Sam Rowling</h5>
                        <h6 class="post text-center ">Team Leader - otstrab</h6>
                        <p class="team-para">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                            galley of type and scrambled it to make a type specimen book.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-10 offset-lg-1 speker-container">
                <div class="text-center d-flex">
                    <div class="team-img">
                        <img src="assets/images/team-fadi/social.webp" alt="" class="img-fluid"
                            style="width: 200px; height: 200px;">
                    </div>
                    <div class="employee">
                        <h5 class="e-name text-center">Vicky Smith</h5>
                        <h6 class="post text-center ">UI/UX Designer - Little Big</h6>
                        <p class="team-para">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                            galley of type and scrambled it to make a type specimen book.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-10 offset-lg-1 speker-container">
                <div class="text-center d-flex">
                    <div class="team-img">
                        <img src="assets/images/team-fadi/branding.webp" alt="" class="img-fluid"
                            style="width: 200px; height: 200px;">
                    </div>
                    <div class="employee">
                        <h5 class="e-name text-center">Sam Rowling</h5>
                        <h6 class="post text-center ">Team Leader - otstrab</h6>
                        <p class="team-para">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                            galley of type and scrambled it to make a type specimen book.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-10 offset-lg-1 speker-container">
                <div class="text-center d-flex">
                    <div class="team-img">
                        <img src="assets/images/team-fadi/branding.webp" alt="" class="img-fluid"
                            style="width: 200px; height: 200px;">
                    </div>
                    <div class="employee">
                        <h5 class="e-name text-center">Mark Tucker</h5>
                        <h6 class="post text-center ">App Developer - Jumpster</h6>
                        <p class="team-para">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                            galley of type and scrambled it to make a type specimen book.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-10 offset-lg-1 speker-container">
                <div class="text-center d-flex">
                    <div class="team-img">
                        <img src="assets/images/team-fadi/branding.webp" alt="" class="img-fluid"
                            style="width: 200px; height: 200px;">
                    </div>
                    <div class="employee">
                        <h5 class="e-name text-center">Sam Rowling</h5>
                        <h6 class="post text-center ">Team Leader - otstrab</h6>
                        <p class="team-para">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                            galley of type and scrambled it to make a type specimen book.
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
<!-- Our Team end--------------------------------------------------------------------------- -->
<!-- ///////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////// -->
<!--  Footer Start-------------------------------------------------------------------------- -->
<footer class="gym footer2">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 set-first">
                <div class="logo-sec">
                    <div class="footer-title mobile-title">
                        <h3 class="text-white">TEAM FADI</h3>
                    </div>
                    <div class="footer-contant">
                        <img src="assets/images/logo/1.png" alt="" class="img-fluid footer-logo">
                        <div class="footer-para">
                            <h6 class="text-white para-address">TEAM FADI</h6>
                            <h6 class="text-white para-address">Office No# 123, Second Floor, </h6>
                            <h6 class="text-white para-address">Karachi, Pakistan.</h6>
                        </div>
                        <ul class="d-flex footer-social social">
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                            </li>
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                            </li>
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-google" aria-hidden="true"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Team footer-headings</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">about</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a href="#">services</a>
                            </li>
                            <li class="">
                                <a href="#">projects</a>
                            </li>
                            <li class="">
                                <a href="#">Clients</a>
                            </li>
                            <li class="">
                                <a href="#">Contact US</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Services</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Services-01</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a href="#">Services-02</a>
                            </li>
                            <li class="">
                                <a href="#">Services-03</a>
                            </li>
                            <li class="">
                                <a href="#">Services-04</a>
                            </li>
                            <li class="">
                                <a href="#">Services-05</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white"> Projects</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Projects-01</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a href="#">Projects-02</a>
                            </li>
                            <li class="">
                                <a href="#">Projects-03</a>
                            </li>
                            <li class="">
                                <a href="#">Projects-04</a>
                            </li>
                            <li class="">
                                <a href="#">Projects-05</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 col-sm-12 set-last">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Post Tags</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Post Tags</h5>
                    <div class="link-btns">
                        <ul>
                            <li class="buttons">
                                <a href="#">app</a>
                            </li>
                            <li class="buttons">
                                <a href="#">business</a>
                            </li>
                            <li class="buttons">
                                <a href="#">corporation</a>
                            </li>
                            <li class="buttons">
                                <a href="#">creative</a>
                            </li>
                            <li class="buttons">
                                <a href="#">design</a>
                            </li>
                            <li class="buttons">
                                <a href="#">fashion</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    </div>
</footer>
<app-tap-to-top></app-tap-to-top>
<!--  Footer End---------------------------------------------------------------------------- -->
<!-- //////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////// -->
<!--  Copyright Start----------------------------------------------------------------------- -->
<div class="gym copyright">
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
                <div class="link-horizontal">
                    <ul>
                        <li>
                            <a href="#" class="copyright-text op-text">Privacy Policy</a>
                        </li>
                        <li>
                            <a href="#" class="copyright-text op-text">Terms & Conditions</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-sm-6">
                <div>
                    <h6 class="copyright-text text-white text-right op-text">Copyright ©2023 by <i class="fa fa-heart"
                            aria-hidden="true"></i>
                        Team Fadi</h6>
                </div>
            </div>
        </div>
    </div>
</div>
<!--  Copyright End------------------------------------------------------------------------- -->
<!-- ///////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////// -->
<!-- ------------------------------------------The End-------------------------------------- -->