import { Component, OnInit } from '@angular/core';
import { transition, trigger, useAnimation } from '@angular/animations';
import { fadeInLeft } from 'ng-animate';

@Component({

    selector: 'team-fadi-index',
    templateUrl: 'team-fadi-index.component.html',
    animations: [
        trigger('fadeInLeft', [transition('* => *', useAnimation(fadeInLeft, {
            params: { timing: 3, delay: 1 }
        }))])
    ],

})
export class TeamFadiIndexComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }
    //<!-- Navigation Start------------------------------------------------------------------------- -->
    //<!-- Navigation End--------------------------------------------------------------------------- -->
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    //<!-- header start----------------------------------------------------------------------------- -->
    banner = [
        {
            img: 'assets/images/team-fadi/header-01.jpg',
            title: 'Web Desigining',
            description: 'I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system.',
            btn: 'Brochure',
        },
        {
            img: 'assets/images/team-fadi/header-02.jpg',
            title: 'UI/UX',
            description: 'I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system.',
            btn: 'Brochure',
        },
        {
            img: 'assets/images/team-fadi/header-03.jpg',
            title: 'Consulting',
            description: 'I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system.',
            btn: 'Brochure',
        },
        {
            img: 'assets/images/team-fadi/header-04.jpg',
            title: 'Providing',
            description: 'I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system.',
            btn: 'Brochure',
        },
    ]
    ecombannerOptions = {
        items: 1,
        dots: false,
        autoplay: true,
        slideSpeed: 300,
        loop: true
    }
    //<!-- header end ------------------------------------------------------------------------------  -->
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    // <!-- About Start------------------------------------------------------------------------------ -->
    // <!-- About End-------------------------------------------------------------------------------- -->
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    //<!-- service start----------------------------------------------------------------------------- -->
    experts = [
        {
            img: "assets/images/resume/service-exprience/1.png",
            type: 'UI Design',
            designation: "Unlike team chat apps, Flow is designed to help your team stay focused by keeping relevant and actionable."
        },
        {
            img: "assets/images/resume/service-exprience/1.png",
            type: 'UX Design',
            designation: "Unlike team chat apps, Flow is designed to help your team stay focused by keeping relevant and actionable."
        },
        {
            img: "assets/images/resume/service-exprience/2.png",
            type: 'Web Design',
            designation: "Unlike team chat apps, Flow is designed to help your team stay focused by keeping relevant and actionable."
        },
        {
            img: "assets/images/resume/service-exprience/3.png",
            type: 'Web Design',
            designation: "Unlike team chat apps, Flow is designed to help your team stay focused by keeping relevant and actionable."
        },
        {
            img: "assets/images/resume/service-exprience/4.png",
            type: 'Web Design',
            designation: "Unlike team chat apps, Flow is designed to help your team stay focused by keeping relevant and actionable."
        },
        {
            img: "assets/images/resume/service-exprience/5.png",
            type: 'Web Develpment',
            designation: "Unlike team chat apps, Flow is designed to help your team stay focused by keeping relevant and actionable."
        },
        {
            img: "assets/images/resume/service-exprience/6.png",
            type: 'Web Develpment',
            designation: "Unlike team chat apps, Flow is designed to help your team stay focused by keeping relevant and actionable."
        },
        {
            img: "assets/images/resume/service-exprience/7.png",
            type: 'Web Develpment',
            designation: "Unlike team chat apps, Flow is designed to help your team stay focused by keeping relevant and actionable."
        },
        {
            img: "assets/images/resume/service-exprience/8.png",
            type: 'Web Develpment',
            designation: "Unlike team chat apps, Flow is designed to help your team stay focused by keeping relevant and actionable."
        },

    ]
    bannerOptions = {
        items: 3,
        dots: false,
        autoplay: true,
        slideSpeed: 300,
        loop: true
    }
    //<!-- service start----------------------------------------------------------------------------- -->
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    //<!--  project Start---------------------------------------------------------------------------- -->
    trainercarousel = [
        {
            img: "assets/images/team-fadi/branding-3.png",
            title: "Asim Bhai"
        },
        {
            img: "assets/images/team-fadi/branding-4.png",
            title: "Adnan Bhai"
        },
        {
            img: "assets/images/team-fadi/branding-5.png",
            title: "Waqaas Bhai"
        },
        {
            img: "assets/images/team-fadi/branding-2.png",
            title: "Haris"
        },
        {
            img: "assets/images/team-fadi/branding-1.png",
            title: "Muhammad Ali"
        },
    ]
    trainercarouselOptions = {
        center: true,
        loop: true,
        dots: true,
        margin: 0,
        responsive: {
            0: {
                items: 2,
                margin: 10
            },
            576: {
                items: 3
            },
            768: {
                items: 4
            },
            1200: {
                items: 6
            }
        }
    }
    // <!--  project End----------------------------------------------------------------------------- -->
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    //<!-- success start----------------------------------------------------------------------------- -->
    about = [
        {
            name: 'Photoshop',
            timer: '95%',
            exp: '4 Years Experince'
        },
        {
            name: 'illustrator',
            timer: '95%',
            exp: '4 Years Experince'
        },
        {
            name: 'html & css',
            timer: '95%',
            exp: '4 Years Experince'
        },
        {
            name: 'wordpress',
            timer: '95%',
            exp: '4 Years Experince'
        }
    ]
    //<!-- success end------------------------------------------------------------------------------- -->
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    //<!-- clients start---------------------------------------------------------------------------- -->
    brands = [
        {
            img: "assets/images/gym/brand/1.jpg"
        },
        {
            img: "assets/images/gym/brand/2.jpg"
        },
        {
            img: "assets/images/gym/brand/3.jpg"
        },
        {
            img: "assets/images/gym/brand/1.jpg"
        },
        {
            img: "assets/images/gym/brand/2.jpg"
        },
        {
            img: "assets/images/gym/brand/3.jpg"
        },
        {
            img: "assets/images/gym/brand/1.jpg"
        },
        {
            img: "assets/images/gym/brand/2.jpg"
        },
        {
            img: "assets/images/gym/brand/3.jpg"
        },
    ]
    brandCarouselOptions = {
        items: 5,
        margin: 40,
        autoHeight: true,
        nav: false,
        dots: false,
        autoplay: true,
        slideSpeed: 300,
        paginationSpeed: 400,
        loop: true,
        responsive: {
            0: {
                items: 1,
                margin: 40
            },
            360: {
                items: 2,
                margin: 20
            },
            480: {
                items: 3,
                margin: 30
            },
            768: {
                items: 5,
                margin: 30
            },
            1366: {
                items: 6
            }
        }
    }
    //<!-- clients end------------------------------------------------------------------------------ -->
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    //<!-- Purpose start---------------------------------------------------------------------------- -->
    users = [
        {
            img: "assets/images/gym/testimonial/quote.png",
            description: 'We’ve all heard stories of people encountering the world’s greatest riches, ending in either tragedy or triumph. Those of Annie, the struggling orphan whose charm ushered her into a life of privilege and wealth. Those of Cinderella, the mistreated servant whose resilience and character led her to wealth. Even those of King Midas, whose greed and desire for wealth left him tragically alone and impoverished. '
        },
        {
            img: "assets/images/gym/testimonial/quote.png",
            description:'But why do we read these stories? Why are we so devoted to these characters, even when we know they’re helpless? What do we want?'
        },
        {
            img: "assets/images/gym/testimonial/quote.png",
            description: 'The truth is, we all want to be rich and make money– and as soon as possible. We read these tales to try and mimic, or avoid, the behaviors of those we read about. We try to learn from them, to try and encounter money like they do, and most importantly, to try and be wealthy like them.'
        },
        {
            img: "assets/images/gym/testimonial/quote.png",
            description: 'We’ve been brought up to believe wealth can give us certain types of freedom, something that no other possession can bring. Freedom from depending on others. Freedom to explore and use your time the way you want to. Freedom to do what you want.'
        }
    ]
    testimonialCarouselOptions = {
        items: 1,
        margin: 0,
        dots: true,
        nav: false,
        autoplay: true,
        slideSpeed: 300,
        paginationSpeed: 400,
        loop: true
    }
    //<!-- Purpose end------------------------------------------------------------------------------- -->
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    //<!--  Footer Start----------------------------------------------------------------------------- -->
    //<!--  Footer End------------------------------------------------------------------------------- -->
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    // <!--  Copyright Start------------------------------------------------------------------------- -->
    // <!--  Copyright End--------------------------------------------------------------------------- -->
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    //<!-- --------------------------------------The End--------------------------------------------- -->

}
