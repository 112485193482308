<!--component html goes here -->
<!-- Navigation bar Start--------------------------------------------------------------------------- -->
<header class="gym loding-header header-fixed position-fixed nav-abs custom-scroll nav-lg"
    [ngStyle]="{'background-color': 'rgb(255 212 0 / 80%)'  }">
    <div class="container">
        <div class="row">
            <div class="col">
                <nav>
                    <a href="#" class="d-inline-block m-r-auto">
                        <img src="assets/images/logo/1.png" alt="" class="img-fluid">
                    </a>
                    <app-menu></app-menu>
                </nav>
            </div>
        </div>
    </div>
</header>
<!-- Navigation bar End----------------------------------------------------------------------------- -->
<!--////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////-->
<!-- Header Start----------------------------------------------------------------------------------- -->
<section class="gym header" id="home">
    <div class="header5-content">
        <owl-carousel-o [options]="headercarouselOptions" class="gym-slider">
            <ng-container *ngFor="let header of headercarousel" class="scroll">
                <ng-template carouselSlide class="item">
                    <div class="gym-header bg" class="bg-scroll" [ngStyle]="{'background-image': 'url(assets/images/team-fadi/header-01.jpg)'}" style="background-repeat: no-repeat; background-position: center; background-size: cover; object-fit: fill;">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-10 offset-md-1">
                                    <div class="center-text">
                                        <div class="text-center">
                                            <div class="header-text">
                                                <h1><span
                                                        class="bold-text">{{header.tagLine1}}</span>{{header.tagLine2}}
                                                </h1>
                                            </div>
                                            <div class="header-sub-text">
                                                <p class="text-white p-light">{{header.description}}</p>
                                            </div>
                                            <div class="link-horizontal">
                                                <ul class="justify-content-center">
                                                    <li>
                                                        <a class=" btn btn-default"><span>register
                                                                20%<sup>off</sup></span></a>
                                                    </li>
                                                    <li>
                                                        <a class=" btn btn-default">start now</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ng-container>
        </owl-carousel-o>
    </div>
</section>
<!-- Header End------------------------------------------------------------------------------------- -->
<!--////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////-->
<!-- branding Start--------------------------------------------------------------------------------- -->
<section class="app1 download">
    <div class="container">
        <div class="row">
            <div class=" offset-md-2 col-md-8">
                <div class="title title2">
                    <div class="sub-title">
                        <div class="">
                            <h2 class="title-text" style="margin-bottom: 100px;"> Our services</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 offset-lg-1 col-md-6">
                <div class="center-text">
                    <div>
                        <div class="download-img d-flex">
                            <div class="set-relative">
                                <img src="assets/images/resume/service-exprience/6.png" alt=""
                                     class="download-icon img-fluid">
                                <div class="set-abs elipse center-abs">
                                    <img src="assets/images/app_landing1/download/Ellipse.png" alt="">
                                </div>
                            </div>
                            <div class="center-img-content m-l-15">
                                <h3 class="m-b-5">Branding</h3> 
                               <p class="font-primary borders-before"> <span>Brand Agency</span> </p>
                            </div>
                        </div>
                        <div class="information">
                            <p class="p-light about-para">Whether you’re starting with a blank canvas or you’re in need
                                of a rebrand – our team carry the expertise to help you level up! We will work with you
                                to create a blisteringly brilliant visual identity (including a logo, color scheme & all
                                other brand elements), and to powerfully define your messaging, positioning, voice &
                                values. Once finished, we’ll put together a brand bible for you to refer back to</p>
                            <p class="p-light about-para">
                                <li class="change">> DIGITAL STRATEGY</li>
                                <li class="change">> BRAND STRATEGY</li>
                                <li class="change">> BRAND IDENTITY</li>
                                <li class="change">> LOGO DESIGN </li>
                            </p>
                        </div>
                        <a href="javascript:void(0)" class="btn btn-default btn-gradient text-white">Get In Touch</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-6 hide-on-init" data-aos="fade-right"  [@fadeInLeft]="fadeInLeft" data-aos-duration="1500">
                <div class="center-content center-mobile">
                    <img src="assets/images/team-fadi/branding.webp" alt="" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- branding End----------------------------------------------------------------------------------- -->
<!--////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////-->
<!--Web design Start-------------------------------------------------------------------------------- -->
<section class="app1 download">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-6 hide-on-init" data-aos="fade-right"  [@fadeInLeft]="fadeInLeft" data-aos-duration="1500">
                <div class="center-content center-mobile">
                    <img src="assets/images/team-fadi/web.webp" alt="" class="img-fluid">
                </div>
            </div>
            <div class="col-lg-6 offset-lg-1 col-md-6">
                <div class="center-text">
                    <div>
                        <div class="download-img d-flex">
                            <div class="set-relative">
                                <img src="assets/images/resume/service-exprience/5.png" alt=""
                                     class="download-icon img-fluid">
                                <div class="set-abs elipse center-abs">
                                    <img src="assets/images/app_landing1/download/Ellipse.png" alt="">
                                </div>
                            </div>
                            <div class="center-img-content m-l-15">
                                <h3 class="m-b-5">Web Desiginig</h3>
                                <p class="font-primary borders-before"> <span>Web Design Agency</span> </p>
                            </div>
                        </div>
                        <div class="information">
                            <p class="p-light about-para">Our team of techie talents have decades of combined experience
                                in designing & developing completely bespoke websites using WordPress, Shopify &
                                HubSpot. Whether you’re a startup or an established enterprise, we can sprinkle our
                                digital magic to conjure you up a beautiful website.</p>
                            <p class="p-light about-para">
                                Don’t settle for a sub-par web design agency. Go with the best.
                                <li class="change">> PIXEL PERFECt </li>
                                <li class="change">> WORDPRESS, SHOPIFY, HUBSPOT & MORE</li>
                                <li class="change">> CUSTOM DESIGN WITH FIGMA</li>
                                <li class="change">> ULTRA-FAST EXPEDITED SERVICE AVAILABLE</li>
                            </p>
                        </div>
                        <a href="javascript:void(0)" class="btn btn-default btn-gradient text-white">Get In Touch</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Web design End--------------------------------------------------------------------------------- -->
<!--////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////-->
<!-- Digital Marketing Start------------------------------------------------------------------------ -->
<section class="app1 download">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 offset-lg-1 col-md-6">
                <div class="center-text">
                    <div>
                        <div class="download-img d-flex">
                            <div class="set-relative">
                                <img src="assets/images/resume/service-exprience/4.png" alt=""
                                     class="download-icon img-fluid">
                                <div class="set-abs elipse center-abs">
                                    <img src="assets/images/app_landing1/download/Ellipse.png" alt="">
                                </div>
                            </div>
                            <div class="center-img-content m-l-15">
                                <h3 class="m-b-5">Digital Marketing</h3> 
                               <p class="font-primary borders-before"> <span>Marketing Agency</span> </p>
                            </div>
                        </div>
                        <div class="information">
                            <p class="p-light about-para">Your business is unique, as are your digital marketing needs.
                                Our in-house technical wizardry comprises of deep expertise in the whole digital
                                landscape. From SEO to PPC (and every acronym inbetween), nobody provides digital
                                marketing services like us.</p>
                            <p class="p-light about-para">Each business has a unique fingerprint, so we create
                                strategies and campaigns that are truly bespoke to you. Our in-house digital marketing
                                geniuses are dab hands in all channels, from PPC to CRO (and every acronym
                                inbetween).<br>
                                In short – nobody provides digital marketing services like us. Let us tailor a digital
                                marketing strategy that keeps your fingers on the pulse and fits you like a glove.</p>
                        </div>
                        <a href="javascript:void(0)" class="btn btn-default btn-gradient text-white">Get In Touch</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-6 hide-on-init" data-aos="fade-right"  [@fadeInLeft]="fadeInLeft" data-aos-duration="1500">
                <div class="center-content center-mobile">
                    <img src="assets/images/team-fadi/digital.webp" alt="" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Digital Marketing End-------------------------------------------------------------------------- -->
<!--////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////-->
<!--SEO Start--------------------------------------------------------------------------------------- -->
<section class="app1 download">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-6 hide-on-init" data-aos="fade-right"  [@fadeInLeft]="fadeInLeft" data-aos-duration="1500">
                <div class="center-content center-mobile">
                    <img src="assets/images/team-fadi/seo.webp" alt="" class="img-fluid">
                </div>
            </div>
            <div class="col-lg-6 offset-lg-1 col-md-6">
                <div class="center-text">
                    <div>
                        <div class="download-img d-flex">
                            <div class="set-relative">
                                <img src="assets/images/resume/service-exprience/3.png" alt=""
                                     class="download-icon img-fluid">
                                <div class="set-abs elipse center-abs">
                                    <img src="assets/images/app_landing1/download/Ellipse.png" alt="">
                                </div>
                            </div>
                            <div class="center-img-content m-l-15">
                                <h3 class="m-b-5">Search Engine Optimization</h3>
                                <p class="font-primary borders-before"> <span>SEO Agency</span> </p>
                            </div>
                        </div>
                        <div class="information">
                            <p class="p-light about-para">We’ve developed deep expertise in Search Engine Optimization,
                                and are a top-rated SEO agency. Reliable, organic traffic is an unmatched growth source,
                                and knowing how to achieve it is in our blood. Whether it’s on or off-site SEO, stop
                                your search right here.</p>
                        </div>
                        <a href="javascript:void(0)" class="btn btn-default btn-gradient text-white">Get In Touch</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- SEO End---------------------------------------------------------------------------------------- -->
<!--////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////-->
<!-- social media Start----------------------------------------------------------------------------- -->
<section class="app1 download">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 offset-lg-1 col-md-6">
                <div class="center-text">
                    <div>
                        <div class="download-img d-flex">
                            <div class="set-relative">
                                <img src="assets/images/resume/service-exprience/2.png" alt=""
                                     class="download-icon img-fluid">
                                <div class="set-abs elipse center-abs">
                                    <img src="assets/images/app_landing1/download/Ellipse.png" alt="">
                                </div>
                            </div>
                            <div class="center-img-content m-l-15">
                                <h3 class="m-b-5">Social Media</h3>
                                <p class="font-primary borders-before"> <span>Social Agency</span> </p>
                            </div>
                        </div>
                        <div class="information">
                            <p class="p-light about-para">From Twitter to TikTok, and every platform, reel and piece of
                                content between the two – we’ve got you covered. Managing social media platforms and
                                content creation comes native to us, but we don’t stop there. We nurture your audience
                                into a meaningful community</p>
                        </div>
                        <a href="javascript:void(0)" class="btn btn-default btn-gradient text-white">Get In Touch</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-6 hide-on-init" data-aos="fade-right"  [@fadeInLeft]="fadeInLeft" data-aos-duration="1500">
                <div class="center-content center-mobile">
                    <img src="assets/images/team-fadi/social.webp" alt="" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- social media End------------------------------------------------------------------------------ -->
<!--///////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////-->
<!--Graphic Desiginig Start------------------------------------------------------------------------ -->
<section class="app1 download">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-6 hide-on-init" data-aos="fade-right"  [@fadeInLeft]="fadeInLeft" data-aos-duration="1500">
                <div class="center-content center-mobile">
                    <img src="assets/images/team-fadi/graphic.webp" alt="" class="img-fluid">
                </div>
            </div>
            <div class="col-lg-6 offset-lg-1 col-md-6">
                <div class="center-text">
                    <div>
                        <div class="download-img d-flex">
                            <div class="set-relative">
                                <img src="assets/images/resume/service-exprience/1.png" alt=""
                                     class="download-icon img-fluid">
                                <div class="set-abs elipse center-abs">
                                    <img src="assets/images/app_landing1/download/Ellipse.png" alt="">
                                </div>
                            </div>
                            <div class="center-img-content m-l-15">
                                <h3 class="m-b-5">Graphic Desiginig </h3>
                                <p class="font-primary borders-before"> <span>Graphic Agency</span> </p>
                            </div>
                        </div>
                        <div class="information">
                            <p class="p-light about-para">We’ve developed deep expertise in Search Engine Optimization,
                                and are a top-rated SEO agency. Reliable, organic traffic is an unmatched growth source,
                                and knowing how to achieve it is in our blood. Whether it’s on or off-site SEO, stop
                                your search right here.</p>
                        </div>
                        <a href="javascript:void(0)" class="btn btn-default btn-gradient text-white">Get In Touch</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- graphic desiging End--------------------------------------------------------------------------- -->
<!--////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////-->
<!--services start------------------------------------------------------------------------------------->
<section class="resume services bg-pink">
    <div class="container">
        <div class="row">
            <div class=" offset-md-2 col-md-8">
                <div class="title title2">
                    <h6 class="font-primary borders main-text text-uppercase"><span>details</span></h6>
                    <div class="sub-title">
                        <div class="">
                            <h2 class="title-text">services & experience</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3 col-sm-6" *ngFor="let data of service">
                <div class="service text-center bg-white">
                    <div class="img-block">
                        <img [src]="data.img" alt="" class="service-img img-fluid">
                    </div>
                    <div class="service-feature">
                        <h4 class="feature-text text-center">{{data.type}}</h4>
                        <p>Unlike team chat apps, Flow is designed to help your team stay focused by keeping relevant
                            and actionable.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--services start---------------------------------------------------------------------------------- -->
<!--////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////-->
<!-- slider start----------------------------------------------------------------------------------- -->
<section class="ecommerce pt-0 ecommerce-home" style="margin-bottom: 50px;">
    <owl-carousel-o [options]="fadibannerOptions" class="ecommerce-home-slider">
        <ng-container *ngFor="let data of banners">
            <ng-template carouselSlide>
                <div class="item-class" [ngStyle]="{'background-image': 'url('+ data.img +')'}"
                    style="background-position: center; background-size: cover; background-clip: border-box; object-fit:fill;">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-5 col-lg-6 col-sm-8">
                                <div class="contain">
                                    <div>
                                        <h1
                                            style="color:transparent; -webkit-text-stroke: 0.2px white; font-size: 40px; font-weight: 900;">
                                            {{data.title}}</h1>
                                        <p>{{data.description}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ng-container>
    </owl-carousel-o>
</section>
<!-- slider end------------------------------------------------------------------------------------- -->
<!--////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////-->
<!--  Footer Start---------------------------------------------------------------------------------- -->
<footer class="gym footer2">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 set-first">
                <div class="logo-sec">
                    <div class="footer-title mobile-title">
                        <h3 class="text-white">TEAM FADI</h3>
                    </div>
                    <div class="footer-contant">
                        <img src="assets/images/logo/1.png" alt="" class="img-fluid footer-logo">
                        <div class="footer-para">
                            <h6 class="text-white para-address">TEAM FADI</h6>
                            <h6 class="text-white para-address">Office No# 123, Second Floor, </h6>
                            <h6 class="text-white para-address">Karachi, Pakistan.</h6>
                        </div>
                        <ul class="d-flex footer-social social">
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                            </li>
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                            </li>
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-google" aria-hidden="true"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Team footer-headings</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">about</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a href="#">services</a>
                            </li>
                            <li class="">
                                <a href="#">projects</a>
                            </li>
                            <li class="">
                                <a href="#">Clients</a>
                            </li>
                            <li class="">
                                <a href="#">Contact US</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Services</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Services-01</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a href="#">Services-02</a>
                            </li>
                            <li class="">
                                <a href="#">Services-03</a>
                            </li>
                            <li class="">
                                <a href="#">Services-04</a>
                            </li>
                            <li class="">
                                <a href="#">Services-05</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white"> Projects</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Projects-01</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a href="#">Projects-02</a>
                            </li>
                            <li class="">
                                <a href="#">Projects-03</a>
                            </li>
                            <li class="">
                                <a href="#">Projects-04</a>
                            </li>
                            <li class="">
                                <a href="#">Projects-05</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 col-sm-12 set-last">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Post Tags</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Post Tags</h5>
                    <div class="link-btns">
                        <ul>
                            <li class="buttons">
                                <a href="#">app</a>
                            </li>
                            <li class="buttons">
                                <a href="#">business</a>
                            </li>
                            <li class="buttons">
                                <a href="#">corporation</a>
                            </li>
                            <li class="buttons">
                                <a href="#">creative</a>
                            </li>
                            <li class="buttons">
                                <a href="#">design</a>
                            </li>
                            <li class="buttons">
                                <a href="#">fashion</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    </div>
</footer>
<app-tap-to-top></app-tap-to-top>
<!--  Footer End------------------------------------------------------------------------------------ -->
<!--////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////-->
<!--  Copyright Start------------------------------------------------------------------------------- -->
<div class="gym copyright">
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
                <div class="link-horizontal">
                    <ul>
                        <li>
                            <a href="#" class="copyright-text op-text">Privacy Policy</a>
                        </li>
                        <li>
                            <a href="#" class="copyright-text op-text">Terms & Conditions</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-sm-6">
                <div>
                    <h6 class="copyright-text text-white text-right op-text">Copyright ©2023 by <i class="fa fa-heart"
                            aria-hidden="true"></i>
                        Team Fadi</h6>
                </div>
            </div>
        </div>
    </div>
</div>
<!--  Copyright End--------------------------------------------------------------------------------- -->
<!--////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////-->
<!-- -----------------------------------------The End----------------------------------------------- -->




















































<!-- <section class="gym format" id="about">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="center-text">
                    <div>
                        <div class="format-small-text">
                            <h6 class="font-primary borders-before"><span>SEO Agency</span></h6>
                        </div>
                        <div class="format-head-text">
                            <h3 class="about-font-header gradient-text">Search Engine Optimization</h3>
                        </div>
                        <div class="format-sub-text">
                            <p class="p-light about-para">We’ve developed deep expertise in Search Engine Optimization,
                                and are a top-rated SEO agency. Reliable, organic traffic is an unmatched growth source,
                                and knowing how to achieve it is in our blood. Whether it’s on or off-site SEO, stop
                                your search right here.</p>
                        </div>
                        <a href="javascript:void(0)" class="btn btn-default btn-gradient text-white">Get In Touch</a>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="text-center center-content">
                    <div class="about-img">
                        <div class="borders-around">
                            <div>
                                <div class="bg-black"></div>
                            </div>
                            <img src="assets/images/team-fadi/seo.webp" alt="" class="img-fluid format-img">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->