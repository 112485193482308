<!--component html goes here -->
<!-- Navigation bar Start------------------------------------------------------------------- -->
<header class="gym loding-header header-fixed position-fixed nav-abs custom-scroll nav-lg"
    [ngStyle]="{'background-color': 'rgb(255 212 0 / 80%)'  }">
    <div class="container">
        <div class="row">
            <div class="col">
                <nav>
                    <a href="#" class="d-inline-block m-r-auto">
                        <img src="assets/images/logo/1.png" alt="" class="img-fluid">
                    </a>
                    <app-menu></app-menu>
                </nav>
            </div>
        </div>
    </div>
</header>
<!-- Navigation bar End--------------------------------------------------------------------- -->
<!-- ///////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////// -->
<!-- Header Start--------------------------------------------------------------------------- -->
<section class="gym header" id="home">
    <div class="header5-content">
        <owl-carousel-o [options]="headercarouselOptions" class="gym-slider">
            <ng-container *ngFor="let header of headercarousel">
                <ng-template carouselSlide class="item">
                    <div class="gym-header bg"
                        [ngStyle]="{'background-image': 'url(assets/images/team-fadi/header-04.jpg)'}">

                        <div class="container">
                            <div class="row">
                                <div class="col-md-10 offset-md-1">
                                    <div class="center-text">
                                        <div class="text-center">
                                            <div class="header-text">
                                                <h1><span class="bold-text">{{header.tagLine1}}
                                                    </span>
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ng-container>
        </owl-carousel-o>
    </div>
</section>
<!-- Header End--------------------------------------------------------------------------- -->
<!-- ///////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////// -->
<!-- project Start-------------------------------------------------------------------------- -->
<section id="how-work" class="saas1 howitwork" [ngStyle]="{'background-image': 'url(assets/images/saas1/work-bg.png)'}">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <div class="title">
                    <div class="main-title">
                        <h2>Branding Projects</h2>
                    </div>
                    <hr>
                    <div class="sub-title">
                        <p class="p-padding">Guide to setup and configuration. You can present below a guide and a
                            description of how your system configuration works and add some animated screens.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-12 work-tab">
                <ul class="nav nav-pills justify-content-center " id="pills-tab" role="tablist">
                    <li class="nav-item  text-center" *ngFor="let data of nav">
                        <a class="nav-link" id="pills-work1-tab" [ngClass]="{'active show': work == data.title }"
                            (click)="openWorkSlide(data.title)">
                            <img [src]="data.img" alt="tab-image-1" style="width: 120px; height: 120px;">
                            <h6>{{data.title}}</h6>
                            <span></span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="work-tab-bg work-content p-t-50">
        <div class="tab-content text-center" id="pills-tabContent">
            <div class="tab-pane fade show active" *ngFor="let data of content"
                [ngClass]="{'active show': work == data.title }" id="pills-work1" role="tabpanel">
                <!-- <img [src]="data.text" alt="tab-image-1" class="text-white p-light"> -->
                <p style="color: rgb(0, 0, 0);">{{data.description}}</p>

            </div>
        </div>
    </div>
</section>
<!-- project End---------------------------------------------------------------------------- -->
<!-- ///////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////// -->
<!-- project 2 start------------------------------------------------------------------------ -->
<section class="wedding portfolio-section zoom-gallery">
    <div class="container">
        <div class="row">
            <div class="col-md-8 offset-md-2">
                <div class="title">
                    <div class="main-title">
                        <h2 class="gradient-text">Our Projects</h2>
                    </div>
                    <div class="sub-title">
                        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus explicabo doloribus
                            numquam odio distinctio perspiciatis, ducimus vel laborum nostrum modi!</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 filter-section">
                <div class="filter-container isotopeFilters">
                    <ul class="list-inline filter">
                        <li [ngClass]="{active : activeFilter == 'all-img'}">
                            <a href="javascript:void(0)" (click)="filterImg('all-img')" data-filter="*">All </a>
                        </li>
                        <li [ngClass]="{active : activeFilter == 'branding'}">
                            <a href="javascript:void(0)" (click)="filterImg('branding')"
                                data-filter=".shoes">Branding</a>
                        </li>
                        <li [ngClass]="{active : activeFilter == 'web'}">
                            <a href="javascript:void(0)" (click)="filterImg('web')" data-filter=".fashion">web</a>
                        </li>
                        <li [ngClass]="{active : activeFilter == 'graphics'}">
                            <a href="javascript:void(0)" (click)="filterImg('graphics')"
                                data-filter=".bags">graphics</a>
                        </li>
                        <li [ngClass]="{active : activeFilter== 'marketing'}">
                            <a href="javascript:void(0)" (click)="filterImg('marketing')"
                                data-filter=".watch">marketing</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <ngx-masonry [options]="myOptions" #masonry class="masonry-grid m-layout margin_cls"
            *ngIf="activeFilter == 'all-img'">
            <div ngxMasonryItem class="m-width m-3-col" (load)="updateMasonryLayout = !updateMasonryLayout"
                *ngFor="let img of  urlsAll">
                <img class="img-fluid" [src]="img">
            </div>
        </ngx-masonry>
        <ngx-masonry [options]="myOptions" class="masonry-grid m-layout margin_cls" *ngIf="activeFilter == 'branding'">
            <div ngxMasonryItem class="m-width m-3-col" (load)="updateMasonryLayout = !updateMasonryLayout"
                *ngFor="let img of urlsBranding">
                <img class="img-fluid" [src]="img">
            </div>
        </ngx-masonry>
        <ngx-masonry [options]="myOptions" class="masonry-grid m-layout margin_cls" *ngIf="activeFilter == 'web'">
            <div ngxMasonryItem class="m-width m-3-col" (load)="updateMasonryLayout = !updateMasonryLayout"
                *ngFor="let img of urlsWeb">
                <img class="img-fluid" [src]="img">
            </div>
        </ngx-masonry>
        <ngx-masonry [options]="myOptions" class="masonry-grid m-layout margin_cls" *ngIf="activeFilter == 'graphics'">
            <div ngxMasonryItem class="m-width m-3-col" (load)="updateMasonryLayout = !updateMasonryLayout"
                *ngFor="let img of urlsGraphics">
                <img class="img-fluid" [src]="img">
            </div>
        </ngx-masonry>
        <ngx-masonry [options]="myOptions" class="masonry-grid m-layout margin_cls" *ngIf="activeFilter == 'marketing'">
            <div ngxMasonryItem class="m-width m-3-col" (load)="updateMasonryLayout = !updateMasonryLayout"
                *ngFor="let img of urlsMarketing">
                <img class="img-fluid" [src]="img">
            </div>
        </ngx-masonry>
    </div>
</section>
<!-- project 2 end-------------------------------------------------------------------------- -->
<!-- ///////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////// -->
<!--  Footer Start-------------------------------------------------------------------------- -->
<footer class="gym footer2">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 set-first">
                <div class="logo-sec">
                    <div class="footer-title mobile-title">
                        <h3 class="text-white">TEAM FADI</h3>
                    </div>
                    <div class="footer-contant">
                        <img src="assets/images/logo/1.png" alt="" class="img-fluid footer-logo">
                        <div class="footer-para">
                            <h6 class="text-white para-address">TEAM FADI</h6>
                            <h6 class="text-white para-address">Office No# 123, Second Floor, </h6>
                            <h6 class="text-white para-address">Karachi, Pakistan.</h6>
                        </div>
                        <ul class="d-flex footer-social social">
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                            </li>
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                            </li>
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-google" aria-hidden="true"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Team footer-headings</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">about</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a href="#">services</a>
                            </li>
                            <li class="">
                                <a href="#">projects</a>
                            </li>
                            <li class="">
                                <a href="#">Clients</a>
                            </li>
                            <li class="">
                                <a href="#">Contact US</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Services</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Services-01</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a href="#">Services-02</a>
                            </li>
                            <li class="">
                                <a href="#">Services-03</a>
                            </li>
                            <li class="">
                                <a href="#">Services-04</a>
                            </li>
                            <li class="">
                                <a href="#">Services-05</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white"> Projects</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Projects-01</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a href="#">Projects-02</a>
                            </li>
                            <li class="">
                                <a href="#">Projects-03</a>
                            </li>
                            <li class="">
                                <a href="#">Projects-04</a>
                            </li>
                            <li class="">
                                <a href="#">Projects-05</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 col-sm-12 set-last">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Post Tags</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Post Tags</h5>
                    <div class="link-btns">
                        <ul>
                            <li class="buttons">
                                <a href="#">app</a>
                            </li>
                            <li class="buttons">
                                <a href="#">business</a>
                            </li>
                            <li class="buttons">
                                <a href="#">corporation</a>
                            </li>
                            <li class="buttons">
                                <a href="#">creative</a>
                            </li>
                            <li class="buttons">
                                <a href="#">design</a>
                            </li>
                            <li class="buttons">
                                <a href="#">fashion</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    </div>
</footer>
<app-tap-to-top></app-tap-to-top>
<!--  Footer End---------------------------------------------------------------------------- -->
<!-- ///////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////// -->
<!--  Copyright Start----------------------------------------------------------------------- -->
<div class="gym copyright">
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
                <div class="link-horizontal">
                    <ul>
                        <li>
                            <a href="#" class="copyright-text op-text">Privacy Policy</a>
                        </li>
                        <li>
                            <a href="#" class="copyright-text op-text">Terms & Conditions</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-sm-6">
                <div>
                    <h6 class="copyright-text text-white text-right op-text">Copyright ©2023 by
                        <!-- <i class="fa fa-heart" aria-hidden="true"></i> -->
                        Team Fadi
                    </h6>
                </div>
            </div>
        </div>
    </div>
</div>
<!--  Copyright End------------------------------------------------------------------------- -->
<!-- ///////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////// -->
<!-- --------------------------------------The End------------------------------------------ -->