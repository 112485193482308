<!--component html goes here -->
<!-- Navigation bar Start------------------------------------------------------------------- -->
<header class="gym loding-header header-fixed position-fixed nav-abs custom-scroll nav-lg"
    [ngStyle]="{'background-color': 'rgb(255 212 0 / 80%)'  }">
    <div class="container">
        <div class="row">
            <div class="col">
                <nav>
                    <a href="#" class="d-inline-block m-r-auto">
                        <img src="assets/images/logo/1.png" alt="" class="img-fluid">
                    </a>
                    <app-menu></app-menu>
                </nav>
            </div>
        </div>
    </div>
</header>
<!-- Navigation bar End--------------------------------------------------------------------- -->
<!-- ///////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////// -->
<!-- Header Start--------------------------------------------------------------------------- -->
<section class="ecommerce pt-0 ecommerce-home">
    <owl-carousel-o [options]="ecombannerOptions" class="ecommerce-home-slider">
        <ng-container *ngFor="let data of banner">
            <ng-template carouselSlide>
                <div class="item-class" [ngStyle]="{'background-image': 'url('+ data.img +')'}"
                    style="background-position: center; background-size: cover; background-clip: border-box; object-fit:fill;">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-5 col-lg-6 col-sm-8 hide-on-init"  data-aos="fade-right"  [@fadeInLeft]="fadeInLeft" data-aos-duration="1500">
                                <div class="contain">
                                    <div>
                                        <h1
                                            style="color:transparent; -webkit-text-stroke: 0.2px white; font-size: 40px; font-weight: 900;">{{data.title}}
                                        </h1>
                                        <p>{{data.description}}</p>
                                        <a [routerLink]="data.link" class="btn btn-default primary-btn  radius-0"
                                            style="border-radius: 30px;" href="#">{{data.btn}}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ng-container>
    </owl-carousel-o>
</section>
<!-- Header End----------------------------------------------------------------------------- -->
<!-- ///////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////// -->
<!-- About Start---------------------------------------------------------------------------- -->
<section class="gym format" id="about">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="text-center center-content">
                    <div class="about-img">
                        <div class="borders-around">
                            <div>
                                <div class="bg-black"></div>
                            </div>
                            <img src="assets/images/team-fadi/about.jpg" alt="" class="img-fluid format-img">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="center-text">
                    <div>
                        <div class="format-small-text">
                            <h6 class="font-primary borders-before"><span>our progress</span></h6>
                        </div>
                        <div class="format-head-text">
                            <h3 class="about-font-header gradient-text">about us</h3>
                        </div>
                        <div class="format-sub-text">
                            <p class="p-light about-para">Lorem Ipsum is simply dummy text of the printing and
                                typesetting
                                industry. Lorem Ipsum
                                has been the industry's standard dummy text ever since the 1500s.</p>
                            <p class="p-light about-para">When an unknown printer took a galley of type and scrambled it
                                to make
                                a type specimen
                                book. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                Ipsum has been the industry.</p>
                        </div>
                        <a href="javascript:void(0)" class="btn btn-default primary-btn">read more</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- About End------------------------------------------------------------------------------ -->
<!-- ///////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////// -->
<!-- services start------------------------------------------------------------------------- -->
<section class="agency format speaker expert-bg"
    [ngStyle]="{'background-image': 'url(assets/images/agency/team/expert-background.png)'}">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-4">
                <div class="format-container">
                    <h6 class="borders-before text-uppercase font-600">
                        <span class="">Check Out Our Services</span>
                    </h6>
                    <div class="format-head-text">
                        <h2 class="">We Will Ready For <span class="block-span">Your Services</span>
                        </h2>
                    </div>
                    <div class="format-sub-text">
                        <p class="about-para">Lorem ipsum dolor sit amet, consectetur
                            sed do eiusmod tempor incididunt.</p>
                    </div>
                    <a routerLink="/services" routerLinkActive="active" ariaCurrentWhenActive="page" target="_blank"
                        class=" btn btn-default btn-gradient text-white">view more ></a>
                </div>
            </div>
            <div class="col-lg-9 col-md-8">
                <owl-carousel-o [options]="bannerOptions" class="ecommerce-home-slider">
                    <ng-container *ngFor="let expert of experts">
                        <ng-template carouselSlide class="item speker-container">
                            <div class="text-center ">
                                <div class="team-img ">
                                    <img [src]="expert.img" alt="" class="img-fluid">
                                </div>
                                <div class="employee">
                                    <h5 class="e-name text-center font-secondary">{{expert.type}}</h5>
                                    <h6 class="post text-center ">{{expert.designation}}</h6>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>
<router-outlet></router-outlet>
<!--services end---------------------------------------------------------------------------- -->
<!-- ///////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////// -->
<!--  project Start------------------------------------------------------------------------- -->
<section class="gym trainers">
    <div class="container overflow-hide">
        <div class="row">
            <div class="col-12">
                <div class="title">
                    <div class="text-center">
                        <div>
                            <h6 class=" borders-before"><span>OUR PROJECTS</span></h6>
                        </div>
                        <div class="main-title">
                            <h2>PROJECTS WE DONE</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <owl-carousel-o [options]="trainercarouselOptions" class="trainers-slider">
                    <ng-container *ngFor="let trainer of trainercarousel">
                        <ng-template carouselSlide class="item">
                            <img [src]="trainer.img" alt="" class="img-fluid">
                            <div class="text-center trainers-info">
                                <h5 class="post text-center ">{{trainer.title}}</h5>
                                <a href="javascript:void(0)" class="btn btn-default btn-gradient text-white"
                                    style="padding-left: 50px; padding-right: 50px;">Explore ></a>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>

            </div>
        </div>
    </div>
</section>
<!-- project End---------------------------------------------------------------------------- -->
<!-- ///////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////// -->
<!--success start--------------------------------------------------------------------------- -->
<section class="resume about" id="about">
    <div class="container">
        <div class="title title2">
            <div class="row">
                <div class=" offset-md-2 col-md-8">
                    <h6 class="font-primary borders main-text text-uppercase"><span>WHY US</span></h6>
                    <div class="sub-title">
                        <div class="">
                            <h2 class="title-text">TEAM FADI SUCCESS</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3 col-6" *ngFor="let data of about">
                <div class="text-center radial">
                    <div data-label="95%" class="radial-bar radial-bar-primary">
                    </div>
                    <h4 class="color-light m-t-15">{{data.name}}</h4>
                    <div class="border-container">
                        <div class="borders-bottom"></div>
                    </div>
                    <div>
                        <h4>{{data.exp}}</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-center m-t-50">
            <a class="btn btn-default btn-gradient text-white">know more now</a>
        </div>
    </div>
</section>
<!--success end----------------------------------------------------------------------------- -->
<!-- ///////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////// -->
<!-- Our purpose start---------------------------------------------------------------------- -->
<section class="gym testimonial" [ngStyle]="{'background-image': 'url(assets/images/team-fadi/header-02.jpg)'}" style="opacity: 0.8;">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="title">
                    <div class="text-center">
                        <div>
                            <h6 class=" borders-before text-white"><span>OUR PURPOSE</span></h6>
                        </div>
                        <div class="main-title">
                            <h2 class="text-white">What is Our Purpose</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 offset-lg-2">
                <owl-carousel-o [options]="testimonialCarouselOptions" class=" testimonial-slider">
                    <ng-container *ngFor="let user of users">
                        <ng-template carouselSlide class="item">
                            <div class="">
                                <img [src]="user.img" alt="" class="img-fluid m-auto">
                                <div class="text-center testimonial-info">
                                    <p style="color: white; font-size: 20px; font-weight: 500;">{{user.description}}</p>
                                    <!-- <p class="p-light testimonial-para">Lorem Ipsum is simply dummy text of the printing
                                        and
                                        typesetting industry. Lorem
                                        Ipsum
                                        has been the industry's standard dummy text ever since the 1500s, when an
                                        unknown
                                        printer took a galley of type and scrambled it to.</p> -->
                                    <!-- <div class="testimonial-name">
                                        <h5 class="text-white name">Alan Lakor</h5>
                                    </div>
                                    <div class="testimonial-post">
                                        <h6 class="text-white post">CEO OF SC.</h6>
                                    </div> -->
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>
<!-- our purpose end------------------------------------------------------------------------ -->
<!--  FAQ Start----------------------------------------------------------------------------- -->
<section class="gym format">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="text-center center-content">
                    <div class="about-img">
                        <div class="borders-around">
                            <div>
                                <div class="bg-black"></div>
                            </div>
                            <img src="assets/images/team-fadi/about.jpg" alt="" class="img-fluid format-img">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class=" center-text">
                    <div>
                        <section id="faq" class="saas1 faq testimonial-bg">
                            <div class="container">
                                <div class="row">
                                    <div class="col-md-8">
                                        <div class="faq-block">
                                            <div>
                                                <div class="format-small-text">
                                                    <h6 class="font-primary borders-before"><span>FAQ</span></h6>
                                                </div>
                                                <div class="format-head-text">
                                                    <h3 class="about-font-header gradient-text">Frequently Asked
                                                        Questions</h3>
                                                </div>
                                                <div class="accordion faq" id="accordionExample">
                                                    <div class="card">
                                                        <ngb-accordion [closeOthers]="true" activeIds="static-1">
                                                            <ngb-panel id="static-2">
                                                                <ng-template ngbPanelTitle>
                                                                    <a>
                                                                        <div class="fa fa-angle-right rotate"></div>
                                                                    </a>
                                                                    Do I need to make a payment?
                                                                </ng-template>
                                                                <ng-template ngbPanelContent>
                                                                    No one cares about products. People care about
                                                                    ideas. Is a product an idea?
                                                                    Noup. Is a brand? A good one is. No one cares about
                                                                    products. People care about ideas. Is a product an
                                                                    idea?
                                                                    Noup. Is a brand? A good one is.
                                                                </ng-template>
                                                            </ngb-panel>
                                                            <ngb-panel id="static-2">
                                                                <ng-template ngbPanelTitle>

                                                                    <a>
                                                                        <div class="fa fa-angle-right rotate"></div>
                                                                    </a>
                                                                    Do I need to make a payment?

                                                                </ng-template>
                                                                <ng-template ngbPanelContent>

                                                                    No one cares about products. People care about
                                                                    ideas. Is a product an idea?
                                                                    Noup. Is a brand? A good one is. No one cares about
                                                                    products. People care about ideas. Is a product an
                                                                    idea?
                                                                    Noup. Is a brand? A good one is.

                                                                </ng-template>
                                                            </ngb-panel>
                                                            <ngb-panel id="static-3">
                                                                <ng-template ngbPanelTitle>
                                                                    <a>
                                                                        <div class="fa fa-angle-right rotate"></div>
                                                                    </a>
                                                                    Do I need to make a payment?
                                                                </ng-template>
                                                                <ng-template ngbPanelContent>
                                                                    No one cares about products. People care about
                                                                    ideas. Is a product an idea?
                                                                    Noup. Is a brand? A good one is. No one cares about
                                                                    products. People care about ideas. Is a product an
                                                                    idea?
                                                                    Noup. Is a brand? A good one is.
                                                                </ng-template>
                                                            </ngb-panel>
                                                            <ngb-panel id="static-4">
                                                                <ng-template ngbPanelTitle>
                                                                    <a>
                                                                        <div class="fa fa-angle-right rotate"></div>
                                                                    </a>
                                                                    Do I need to make a payment?
                                                                </ng-template>
                                                                <ng-template ngbPanelContent>
                                                                    No one cares about products. People care about
                                                                    ideas. Is a product an idea?
                                                                    Noup. Is a brand? A good one is. No one cares about
                                                                    products. People care about ideas. Is a product an
                                                                    idea?
                                                                    Noup. Is a brand? A good one is.
                                                                </ng-template>
                                                            </ngb-panel>
                                                            <ngb-panel id="static-5">
                                                                <ng-template ngbPanelTitle>
                                                                    <a>
                                                                        <div class="fa fa-angle-right rotate"></div>
                                                                    </a>
                                                                    Do I need to make a payment?
                                                                </ng-template>
                                                                <ng-template ngbPanelContent>
                                                                    No one cares about products. People care about
                                                                    ideas. Is a product an idea?
                                                                    Noup. Is a brand? A good one is. No one cares about
                                                                    products. People care about ideas. Is a product an
                                                                    idea?
                                                                    Noup. Is a brand? A good one is.
                                                                </ng-template>
                                                            </ngb-panel>
                                                        </ngb-accordion>
                                                    </div>
                                                </div>
                                                <h6 class="link">Still have a question? Reach out to us:
                                                    <a>demo@123.com</a>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--  FAQ End------------------------------------------------------------------------------- -->
<!-- ///////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////// -->
<!-- client start--------------------------------------------------------------------------- -->
<section class="wedding saas1 brand-sliders">
    <div class="container">
        <div class="row">
            <div class="col-md-8 offset-md-2">
                <div class="title">
                    <div class="text-center">
                        <div>
                            <h6 class=" borders-before"><span>OUR PROJECTS</span></h6>
                        </div>
                        <div class="main-title">
                            <h2>PROJECTS WE DONE</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <owl-carousel-o [options]="brandCarouselOptions" class="brand-slider">
                    <ng-container *ngFor="let brand of brands">
                        <ng-template carouselSlide class="item">
                            <a href="javascript:void(0)">
                                <img [src]="brand.img" alt="" class="img-fluid wedding-brand">
                            </a>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>
        <div>
        </div>
    </div>
</section>
<!-- client End----------------------------------------------------------------------------- -->
<!-- ///////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////// -->
<!--  Footer Start-------------------------------------------------------------------------- -->
<footer class="gym footer2">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 set-first">
                <div class="logo-sec">
                    <div class="footer-title mobile-title">
                        <h3 class="text-white">TEAM FADI</h3>
                    </div>
                    <div class="footer-contant">
                        <img src="assets/images/logo/1.png" alt="" class="img-fluid footer-logo">
                        <div class="footer-para">
                            <h6 class="text-white para-address">TEAM FADI</h6>
                            <h6 class="text-white para-address">Office No# 123, Second Floor, </h6>
                            <h6 class="text-white para-address">Karachi, Pakistan.</h6>
                        </div>
                        <ul class="d-flex footer-social social">
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                            </li>
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                            </li>
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-google" aria-hidden="true"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Team footer-headings</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">about</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a href="#">services</a>
                            </li>
                            <li class="">
                                <a href="#">projects</a>
                            </li>
                            <li class="">
                                <a href="#">Clients</a>
                            </li>
                            <li class="">
                                <a href="#">Contact US</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Services</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Services-01</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a href="#">Services-02</a>
                            </li>
                            <li class="">
                                <a href="#">Services-03</a>
                            </li>
                            <li class="">
                                <a href="#">Services-04</a>
                            </li>
                            <li class="">
                                <a href="#">Services-05</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white"> Projects</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Projects-01</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a href="#">Projects-02</a>
                            </li>
                            <li class="">
                                <a href="#">Projects-03</a>
                            </li>
                            <li class="">
                                <a href="#">Projects-04</a>
                            </li>
                            <li class="">
                                <a href="#">Projects-05</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 col-sm-12 set-last">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Post Tags</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Post Tags</h5>
                    <div class="link-btns">
                        <ul>
                            <li class="buttons">
                                <a href="#">app</a>
                            </li>
                            <li class="buttons">
                                <a href="#">business</a>
                            </li>
                            <li class="buttons">
                                <a href="#">corporation</a>
                            </li>
                            <li class="buttons">
                                <a href="#">creative</a>
                            </li>
                            <li class="buttons">
                                <a href="#">design</a>
                            </li>
                            <li class="buttons">
                                <a href="#">fashion</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    </div>
</footer>
<app-tap-to-top></app-tap-to-top>
<!--  Footer End---------------------------------------------------------------------------- -->
<!-- ///////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////// -->
<!--  Copyright Start----------------------------------------------------------------------- -->
<div class="gym copyright">
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
                <div class="link-horizontal">
                    <ul>
                        <li>
                            <a href="#" class="copyright-text op-text">Privacy Policy</a>
                        </li>
                        <li>
                            <a href="#" class="copyright-text op-text">Terms & Conditions</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-sm-6">
                <div>
                    <h6 class="copyright-text text-white text-right op-text">Copyright ©2023 by <i class="fa fa-heart"
                            aria-hidden="true"></i>
                        Team Fadi</h6>
                </div>
            </div>
        </div>
    </div>
</div>
<!--  Copyright End------------------------------------------------------------------------- -->
<!-- ///////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////// -->
<!-- ---------------------------------------The End----------------------------------------- -->