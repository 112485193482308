import { Component, OnInit } from '@angular/core';
import { transition, trigger, useAnimation } from '@angular/animations';
import { fadeInLeft } from 'ng-animate';

@Component({
  moduleId: module.id,
  selector: 'team-fadi-services',
  templateUrl: 'team-fadi-services.component.html',
  styleUrls: ['team-fadi-services.component.scss'],
  animations: [
    trigger('fadeInLeft', [transition('* => *', useAnimation(fadeInLeft, {
      params: { timing: 3, delay: 1 }
    }))])
  ],
})
export class TeamFadiServicesComponent implements OnInit {

  fadeInLeft: any;

  constructor() { }

  ngOnInit() {
  }
  // <!-- Navigation Start------------------------------------------------------------------------- -->
  // <!-- Navigation End--------------------------------------------------------------------------- -->
  /////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // <!-- Header Start----------------------------------------------------------------------------- -->
  headercarousel = [
    {
      tagLine1: "build your",
      tagLine2: "body more strong",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took."
    },
  ]

  headercarouselOptions = {
    items: 1,
    margin: 0,
    autoHeight: true,
  }
  // <!-- Header End-------------------------------------------------------------------------------- -->
  //////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////
  // <!-- services start---------------------------------------------------------------------------- -->
  service = [
    {
      img: "assets/images/resume/service-exprience/1.png",
      type: 'UI Design'
    },
    {
      img: "assets/images/resume/service-exprience/2.png",
      type: 'UX Design'
    },
    {
      img: "assets/images/resume/service-exprience/3.png",
      type: 'Web Design'
    },
    {
      img: "assets/images/resume/service-exprience/4.png",
      type: 'Web Design'
    },
    {
      img: "assets/images/resume/service-exprience/5.png",
      type: 'Web Design'
    },
    {
      img: "assets/images/resume/service-exprience/6.png",
      type: 'Web Devloper'
    },
    {
      img: "assets/images/resume/service-exprience/7.png",
      type: 'Web Design'
    },
    {
      img: "assets/images/resume/service-exprience/8.png",
      type: 'Web Design'
    }
  ]
  //<!--  services end--------------------------------------------------------------------------------------- -->
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //<!-- slider start --------------------------------------------------------------------------------------- -->
  banners = [
    {
      img: 'assets/images/team-fadi/header-05.jpeg',
      title: 'Branding',
      description: 'I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system.',
      btn: 'Brochure',
    },
    {
      img: 'assets/images/team-fadi/header-04.jpg',
      title: 'Web Designing',
      description: 'I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system.',
      btn: 'Brochure',
    },
    {
      img: 'assets/images/team-fadi/header-03.jpg',
      title: 'Digital Marketing',
      description: 'I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system.',
      btn: 'Brochure',
    },
    {
      img: 'assets/images/team-fadi/header-02.jpg',
      title: 'SEO',
      description: 'I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system.',
      btn: 'Brochure',
    },
    {
      img: 'assets/images/team-fadi/header-01.jpg',
      title: 'Social media',
      description: 'I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system.',
      btn: 'Brochure',
    },
  ]
  fadibannerOptions = {
    items: 1,
    dots: false,
    autoplay: true,
    setInterval: 3000,
    loop: true
  }
  //<!-- slider end---------------------------------------------------------------------------------------- -->
  // <!--  Footer Start------------------------------------------------------------------------------------ -->
  // <!--  Footer End-------------------------------------------------------------------------------------- -->
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // <!--  Copyright Start--------------------------------------------------------------------------------- -->
  // <!--  Copyright End----------------------------------------------------------------------------------- -->
}
