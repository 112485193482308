import { Component, OnInit } from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'team-fadi-clients',
  templateUrl: 'team-fadi-clients.component.html',
  styleUrls: ['team-fadi-clients.component.scss']
})
export class TeamFadiClientsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }



  // <!-- Navigation Start------------------------------------------------------------------------- -->
  // <!-- Navigation End--------------------------------------------------------------------------- -->
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // <!-- Header Start----------------------------------------------------------------------------- -->
  headercarousel = [
    {
      tagLine1: "build your",
      tagLine2: "body more strong",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took."
    },
  ]
  headercarouselOptions = {
    items: 1,
    margin: 0,
    autoHeight: true,
  }
  // <!-- Header End-------------------------------------------------------------------------------- -->
  //////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////
  //<!-- client start------------------------------------------------------------------------------- -->
  client = [
    {
      img: 'assets/images/team-fadi/branding-1.png'
    },
    {
      img: 'assets/images/team-fadi/branding-2.png'
    },
    {
      img: 'assets/images/team-fadi/branding-3.png'
    },
    {
      img: 'assets/images/team-fadi/branding-4.png'
    },
    {
      img: 'assets/images/team-fadi/branding-5.png'
    },
    {
      img: 'assets/images/team-fadi/branding-1.png'
    },
    {
      img: 'assets/images/team-fadi/branding-2.png'
    },
    {
      img: 'assets/images/team-fadi/branding-3.png'
    },
    {
      img: 'assets/images/team-fadi/branding-4.png'
    },
    {
      img: 'assets/images/team-fadi/branding-5.png'
    },
    {
      img: 'assets/images/team-fadi/branding-1.png'
    },
    {
      img: 'assets/images/team-fadi/branding-2.png'
    }
  ]
  // <!-- client End-------------------------------------------------------------------------------- -->
  //////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////
  //<!-- review start------------------------------------------------------------------------------- -->
  users = [
    {
      img: "assets/images/team-fadi/branding-3.png",
      name: "Kolan Motto",
      designation: "CEO, Therat",
      review: "It goes without saying that customer data sec urity and compliance is top of mind in most retail board rooms compliance is top."
    },
    {
      img: "assets/images/team-fadi/branding-4.png",
      name: "Kolan Motto",
      designation: "CEO, Therat",
      review: "It goes without saying that customer data sec urity and compliance is top of mind in most retail board rooms compliance is top."
    },
    {
      img: "assets/images/team-fadi/branding-5.png",
      name: "Kolan Motto",
      designation: "CEO, Therat",
      review: "It goes without saying that customer data sec urity and compliance is top of mind in most retail board rooms compliance is top."
    },
    {
      img: "assets/images/team-fadi/branding-1.png",
      name: "Kolan Motto",
      designation: "CEO, Therat",
      review: "It goes without saying that customer data sec urity and compliance is top of mind in most retail board rooms compliance is top."
    }
  ]
  testimonialCarouselOptions = {
    items: 3,
    margin: 65,
    nav: true,
    dots: false,
    navText: ['<img src="assets/images/agency/testimonial/left.png">', '<img src="assets/images/agency/testimonial/right.png">'],
    autoplay: true,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true,
    responsive: {
      0: {
        items: 1,
        margin: 10
      },
      575: {
        items: 2,
        margin: 10
      },
      991: {
        items: 2,
        margin: 30
      },
      1199: {
        items: 3,
        margin: 30
      }
    }
  }
  //<!-- review end--------------------------------------------------------------------------------- -->
  // <!--  Footer Start----------------------------------------------------------------------------- -->
  // <!--  Footer End------------------------------------------------------------------------------- -->
  //////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////
  // <!--  Copyright Start-------------------------------------------------------------------------- -->
  // <!--  Copyright End---------------------------------------------------------------------------- -->

}
