import { Component, OnInit } from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'team-fadi-projects',
    templateUrl: 'team-fadi-projects.component.html',
    styleUrls: ['team-fadi-projects.component.scss']
})
export class TeamFadiProjectsComponent implements OnInit {


    ngOnInit() { }

    // <!-- Navigation Start------------------------------------------------------------------- -->
    // <!-- Navigation End--------------------------------------------------------------------- -->
    /////////////////////////////////////////////////////////////////////////////////////////// -->
    /////////////////////////////////////////////////////////////////////////////////////////// -->
 // <!-- Header Start----------------------------------------------------------------------- -->
 headercarousel = [
    {
        tagLine1: "TEAM FADI",
        tagLine2: "WHO WE ARE",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took."
    }
]
headercarouselOptions = {
    items: 1,
    margin: 0,
    autoHeight: true,
}
  // <!-- Header End------------------------------------------------------------------------ -->
    /////////////////////////////////////////////////////////////////////////////////////////// -->
    /////////////////////////////////////////////////////////////////////////////////////////// -->
    // <!-- project start --------------------------------------------------------------------- -->
    public work: any = 'Company 3'
    openWorkSlide(val) {
        this.work = val
    }
    nav = [
        {
            img: 'assets/images/team-fadi/branding-1.png',
            title: 'Company 1'
        },
        {
            img: 'assets/images/team-fadi/branding-2.png',
            title: 'Company 2'
        },
        {
            img: 'assets/images/team-fadi/branding-3.png',
            title: 'Company 3'
        },
        {
            img: 'assets/images/team-fadi/branding-4.png',
            title: 'Company 4'
        },
        {
            img: 'assets/images/team-fadi/branding-5.png',
            title: 'Company 5'
        }
    ]
    content = [
        {
            description: '“The real measure of our wealth is how much we’d be worth if we lost all our money.” – John Henry Jowett We’ve all heard stories of people encountering the world’s greatest riches, ending in either tragedy or triumph. Those of Annie, the struggling orphan whose charm ushered her into a life of privilege and […]',
            title: 'Company 1'
        },
        {
            text: 'assets/images/saas1/tab/Add-instant.png',
            title: 'Add Instances'
        },
        {
            img: 'assets/images/saas1/tab/Configuration.png',
            title: 'Configuration'
        },
        {
            img: 'assets/images/saas1/tab/Api.png',
            title: 'API Setting'
        },
        {
            img: 'assets/images/saas1/tab/Security.png',
            title: 'Security'
        }
    ]
    // <!-- project end------------------------------------------------------------------------ -->
    /////////////////////////////////////////////////////////////////////////////////////////// -->
    /////////////////////////////////////////////////////////////////////////////////////////// -->
    // <!-- all project start------------------------------------------------------------------ -->
    activeFilter = 'all-img'
    private urlsAll: string[] = [
        'assets/images/team-fadi/branding-1.png',
        'assets/images/team-fadi/branding-2.png',
        'assets/images/team-fadi/branding-3.png',
        'assets/images/team-fadi/branding-4.png',
        'assets/images/team-fadi/branding-5.png',
        'assets/images/team-fadi/branding.webp',
        'assets/images/team-fadi/seo.webp'
    ];

    private urlsBranding: string[] = [
        'assets/images/team-fadi/branding.webp',
        'assets/images/team-fadi/seo.webp'
    ];

    private urlsWeb: string[] = [
        'assets/images/team-fadi/branding-1.png',
        'assets/images/team-fadi/branding-2.png'
    ];

    private urlsGraphics: string[] = [
        'assets/images/team-fadi/branding-3.png',
    ];

    private urlsMarketing: string[] = [
        'assets/images/team-fadi/branding-4.png',
        'assets/images/team-fadi/branding-5.png',
    ];



    public filterImg(item) {
        this.activeFilter = item
    }
    // <!-- all project end-------------------------------------------------------------------- -->
    /////////////////////////////////////////////////////////////////////////////////////////// -->
    /////////////////////////////////////////////////////////////////////////////////////////// -->
    // <!--  Footer Start---------------------------------------------------------------------- -->

    // <!--  Footer End------------------------------------------------------------------------ -->
    /////////////////////////////////////////////////////////////////////////////////////////// -->
    /////////////////////////////////////////////////////////////////////////////////////////// -->
    // <!--  Copyright Start------------------------------------------------------------------- -->

    // <!--  Copyright End--------------------------------------------------------------------- -->
}
/////////////////////////////////////////////////////////////////////////////////////////// -->
/////////////////////////////////////////////////////////////////////////////////////////// -->