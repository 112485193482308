import { Component, OnInit } from '@angular/core';
import { blogClassicDB } from '../../shared/data/blog/blog-classic/column-no-sidebar';

@Component({
    moduleId: module.id,
    selector: 'team-fadi-blogs',
    templateUrl: 'team-fadi-blogs.component.html',
    styleUrls: ['team-fadi-blogs.component.scss']
})
export class TeamFadiBlogsComponent implements OnInit {
    public blogData: any

    constructor() {
        this.blogData = blogClassicDB.column;
    }

    ngOnInit() {
    }



    // <!-- Navigation Start------------------------------------------------------------------------- -->
    // <!-- Navigation End--------------------------------------------------------------------------- -->
    /////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////
    // <!-- Header Start----------------------------------------------------------------------------- -->
    headercarousel = [
        {
            tagLine1: "build your",
            tagLine2: "body more strong",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took."
        },
    ]

    headercarouselOptions = {
        items: 1,
        margin: 0,
        autoHeight: true,
    }
    // <!-- Header End-------------------------------------------------------------------------------- -->
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////
    // <!--  Footer Start----------------------------------------------------------------------------- -->
    // <!--  Footer End------------------------------------------------------------------------------- -->
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////
    // <!--  Copyright Start-------------------------------------------------------------------------- -->
    // <!--  Copyright End---------------------------------------------------------------------------- -->
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////
    //<!-- ----------------------------The End-------------------------------------------------------- -->
}
